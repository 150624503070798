@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
*,
*:after,
*:before {
  box-sizing: border-box;
}

:root {
  --base: #ab1f39;
  --black: #000;
  --white: #fff;
  --grey30: #a9616e;
  --grey90: #363636;
  --grey50: #737373;
  --grey40: #b9b9b9;
  --grey30: #f7f7f7;
  --grey20: #f2f2f2;
  --green: #67d114;
  --red: #f11c1c;
  --blue: #3387ff;
  --modal_bg: rgba(17, 24, 39, 0.33);
  --accent_alpha_hover: rgba(171, 31, 57, 0.05);
}

::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body {
  margin: 0;
  height: 100vh;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  overflow: hidden;
}
body .border {
  border: 1px solid var(--black);
}
body #root {
  height: 100vh;
}
body #root .page_main {
  height: 100vh;
}
body #root .page_main .page_header {
  height: 88px;
  background: var(--base);
  color: var(--white);
}
body #root .page_main .page_header > div {
  height: 100%;
  max-width: 1200px;
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 0 auto;
  padding: 0 32px;
}
body #root .page_main .page_header > div .txt {
  font-size: 24px;
  font-weight: 600;
  margin: 0 auto 0 0;
}
body #root .page_main .page_header > div .clear_date {
  width: 0px;
  overflow: hidden;
  transition: width 0.3s linear;
}
body #root .page_main .page_header > div .clear_date.active {
  width: 20px;
}
body #root .page_main .page_header .search {
  height: 36px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background: rgba(255, 255, 255, 0.07);
  border-radius: 0.5rem;
  transition: background 0.3 linear;
}
body #root .page_main .page_header .search:hover {
  background: rgba(255, 255, 255, 0.2);
}
body #root .page_main .page_header .search svg {
  margin: 0 0 0 8px;
}
body #root .page_main .page_header .search svg path {
  fill: rgba(255, 255, 255, 0.7);
}
body #root .page_main .page_header .search input {
  background: transparent;
  outline: 0;
  border: 0;
  font-size: 0.9375rem;
  color: rgba(255, 255, 255, 0.7);
}
body #root .page_main .page_header .search input::-moz-placeholder {
  font-size: 0.9375rem;
  color: rgba(255, 255, 255, 0.7);
}
body #root .page_main .page_header .search input::placeholder {
  font-size: 0.9375rem;
  color: rgba(255, 255, 255, 0.7);
}
body #root .page_main .page_body {
  display: flex;
  height: calc(100% - 80px);
  padding: 14px 28px;
}
body #root .page_main .page_body .table {
  flex-grow: 1;
  transition: width 0.3s linear;
}
body #root .page_main .page_body .page_edit {
  width: 0;
  overflow-y: scroll;
  transition: width 0.3s linear;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  gap: 10px;
}
body #root .page_main .page_body .page_edit > div {
  width: 100%;
}
body #root .page_main .page_body .page_edit .breadcrumb {
  padding: 10px 0 10px 0;
  display: flex;
  gap: 10px;
  color: var(--grey50);
  font-size: 14px;
  position: sticky;
  top: 0;
  background: var(--white);
}
body #root .page_main .page_body .page_edit .breadcrumb .main_link {
  position: relative;
  cursor: pointer;
}
body #root .page_main .page_body .page_edit .breadcrumb .main_link:after {
  position: absolute;
  content: "";
  top: 100%;
  left: 0;
  height: 1px;
  width: 0;
  background: var(--base);
  transition: width 0.3s linear;
}
body #root .page_main .page_body .page_edit .breadcrumb .main_link:hover:after {
  width: 100%;
}
body #root .page_main .page_body .page_edit .breadcrumb .add_txt {
  font-weight: 600;
  color: var(--black);
}
body #root .page_main .page_body .page_edit .btn {
  padding: 8px 16px;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 1.5rem;
  text-align: center;
  font-size: 0.9375rem;
  font-weight: 600;
  position: relative;
}
body #root .page_main .page_body .page_edit .btn:before {
  content: "";
  position: absolute;
  inset: 0;
  margin: auto;
  background: rgba(0, 0, 0, 0);
}
body #root .page_main .page_body .page_edit .btn:hover:before {
  background: rgba(0, 0, 0, 0.05);
}
body #root .page_main .page_body .page_edit .card_title {
  font-size: 24px;
  font-weight: 600;
}
body #root .page_main .page_body .page_edit .card1 {
  border-radius: 0.5rem;
  box-shadow: rgb(230, 232, 234) 0px 0px 0px 1px inset, rgb(230, 232, 234) 0px 0px 0px 0px;
  padding: 28px 24px;
}
body #root .page_main .page_body .page_edit .card1 .address {
  margin: 16px 0 24px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
body #root .page_main .page_body .page_edit .card1 .address > div:first-child {
  width: 50%;
}
body #root .page_main .page_body .page_edit .card1 .address > div:nth-child(2) {
  width: 20%;
}
body #root .page_main .page_body .page_edit .card1 .address > div:last-child {
  width: 30%;
}
body #root .page_main .page_body .page_edit .card1 .address > div > div:first-child {
  color: #7e8691;
}
body #root .page_main .page_body .page_edit .card1 .status_title,
body #root .page_main .page_body .page_edit .card1 .status_chips {
  margin: 24px 0 0 0;
}
body #root .page_main .page_body .page_edit .card1 .status_chips {
  display: flex;
  gap: 10px;
  font-weight: 600;
  color: var(--grey50);
}
body #root .page_main .page_body .page_edit .card1 .status_chips > div {
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 20px;
  box-shadow: var(--chip_color) 0px 0px 0px 1px inset, var(--chip_color) 0px 0px 0px 0px;
  color: var(--chip_color);
}
body #root .page_main .page_body .page_edit .card1 .status_chips > div.active {
  background: var(--chip_color);
  color: var(--white);
  box-shadow: var(--chip_color) 0px 0px 0px 1px inset, var(--chip_color) 0px 0px 0px 0px;
}
body #root .page_main .page_body .page_edit .card2,
body #root .page_main .page_body .page_edit .card3 {
  flex-grow: 1;
}
body #root .page_main .page_body .page_edit .card2,
body #root .page_main .page_body .page_edit .card3 {
  padding: 24px 28px;
  box-shadow: rgb(230, 232, 234) 0px 0px 0px 1px inset, rgb(230, 232, 234) 0px 0px 0px 0px;
  border-radius: 10px;
}
body #root .page_main .page_body .page_edit .card2 .card_group,
body #root .page_main .page_body .page_edit .card3 .card_group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 20px;
  --gap: 10px;
  gap: var(--gap);
}
body #root .page_main .page_body .page_edit .card2 .card_group > div,
body #root .page_main .page_body .page_edit .card2 .card_group label,
body #root .page_main .page_body .page_edit .card3 .card_group > div,
body #root .page_main .page_body .page_edit .card3 .card_group label {
  box-shadow: rgb(230, 232, 234) 0px 0px 0px 1px inset, rgb(230, 232, 234) 0px 0px 0px 0px;
  width: 150px;
  aspect-ratio: 1;
}
body #root .page_main .page_body .page_edit .card2 .card_group > div img,
body #root .page_main .page_body .page_edit .card2 .card_group > div video,
body #root .page_main .page_body .page_edit .card2 .card_group label img,
body #root .page_main .page_body .page_edit .card2 .card_group label video,
body #root .page_main .page_body .page_edit .card3 .card_group > div img,
body #root .page_main .page_body .page_edit .card3 .card_group > div video,
body #root .page_main .page_body .page_edit .card3 .card_group label img,
body #root .page_main .page_body .page_edit .card3 .card_group label video {
  max-width: 100%;
  max-height: 100%;
}
body #root .page_main .page_body .page_edit .card2 .card_group .add,
body #root .page_main .page_body .page_edit .card3 .card_group .add {
  cursor: pointer;
  position: relative;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
body #root .page_main .page_body .page_edit .card2 .card_group .add img,
body #root .page_main .page_body .page_edit .card3 .card_group .add img {
  width: 20%;
}
body #root .page_main .page_body .page_edit .card2 .card_group .add input,
body #root .page_main .page_body .page_edit .card3 .card_group .add input {
  display: none;
}
body #root .page_main .page_body .page_edit .card2 .card_group .items,
body #root .page_main .page_body .page_edit .card3 .card_group .items {
  width: 150px;
  border-radius: 15px;
  aspect-ratio: 1;
  box-shadow: rgb(230, 232, 234) 0px 0px 0px 1px inset, rgb(230, 232, 234) 0px 0px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}
body #root .page_main .page_body .page_edit .card2 .card_group .items img,
body #root .page_main .page_body .page_edit .card2 .card_group .items iframe,
body #root .page_main .page_body .page_edit .card2 .card_group .items video,
body #root .page_main .page_body .page_edit .card3 .card_group .items img,
body #root .page_main .page_body .page_edit .card3 .card_group .items iframe,
body #root .page_main .page_body .page_edit .card3 .card_group .items video {
  max-width: 90%;
  max-height: 90%;
}
body #root .page_main .page_body .page_edit .card2 .card_group .items .delete,
body #root .page_main .page_body .page_edit .card3 .card_group .items .delete {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--white);
  border-bottom-left-radius: 3px;
  cursor: pointer;
}
body #root .page_main .page_body .page_edit .card2 .card_group .items .delete > div,
body #root .page_main .page_body .page_edit .card3 .card_group .items .delete > div {
  height: 80%;
  width: 80%;
  position: relative;
}
body #root .page_main .page_body .page_edit .card2 .card_group .items .delete > div::after, body #root .page_main .page_body .page_edit .card2 .card_group .items .delete > div::before,
body #root .page_main .page_body .page_edit .card3 .card_group .items .delete > div::after,
body #root .page_main .page_body .page_edit .card3 .card_group .items .delete > div::before {
  content: "";
  position: absolute;
  inset: 0;
  margin: auto;
  height: 100%;
  width: 3px;
  background: var(--black);
}
body #root .page_main .page_body .page_edit .card2 .card_group .items .delete > div::after,
body #root .page_main .page_body .page_edit .card3 .card_group .items .delete > div::after {
  transform: rotate(45deg);
}
body #root .page_main .page_body .page_edit .card2 .card_group .items .delete > div::before,
body #root .page_main .page_body .page_edit .card3 .card_group .items .delete > div::before {
  transform: rotate(-45deg);
}
body #root .page_main .page_body.edit_mode .table {
  width: 0%;
  overflow: hidden;
}
body #root .page_main .page_body.edit_mode .table .table_header > div:first-child {
  width: 100%;
  text-align: center;
}
body #root .page_main .page_body.edit_mode .table .table_header > div:not(:first-child) {
  display: none;
}
body #root .page_main .page_body.edit_mode .table .table_body .table_row {
  border-radius: 10px;
  overflow: hidden;
}
body #root .page_main .page_body.edit_mode .table .table_body .table_row > div:first-child {
  width: 100%;
}
body #root .page_main .page_body.edit_mode .table .table_body .table_row > div:not(:first-child) {
  display: none;
}
body #root .page_main .page_body.edit_mode .page_edit {
  width: 100%;
}
body #root .accordian {
  border: 1px solid var(--base);
  border-radius: 5px;
}
body #root .accordian .acc_title {
  align-items: center;
  padding: 10px 15px;
}
body #root .accordian .acc_title .accordian_arrow {
  height: 15px;
  width: 15px;
  position: relative;
}
body #root .accordian .acc_title .accordian_arrow:after, body #root .accordian .acc_title .accordian_arrow:before {
  content: "";
  background: var(--base);
  position: absolute;
  height: 100%;
  width: 3px;
  inset: 0;
  margin: 0 auto;
  transition: transform 0.3s linear;
}
body #root .accordian .acc_title .accordian_arrow:after {
  transform: translate(-145%, 0) rotate(-45deg);
}
body #root .accordian .acc_title .accordian_arrow::before {
  transform: translate(145%, 0) rotate(45deg);
}
body #root .accordian .acc_body {
  overflow: hidden;
  transition: height 0.3s linear;
}
body #root .accordian .acc_body > div {
  padding: 0px 15px 10px 15px;
}
body #root .accordian[active=true] .acc_title .accordian_arrow:after {
  transform: translate(-145%, 0) rotate(45deg);
}
body #root .accordian[active=true] .acc_title .accordian_arrow::before {
  transform: translate(145%, 0) rotate(-45deg);
}
body #root .table {
  display: flex;
  flex-direction: column;
}
body #root .table .table_header {
  display: flex;
  align-items: center;
  font-weight: 600;
  height: 36px;
  padding: 0 0 0 16px;
}
body #root .table .table_header > div {
  width: 25%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: 0.6px;
  text-transform: uppercase;
}
body #root .table .table_header > div:nth-child(1) {
  width: 30%;
}
body #root .table .table_header > div:nth-child(2) {
  width: 20%;
}
body #root .table .table_header > div:nth-child(3) {
  width: 30%;
  text-align: center;
}
body #root .table .table_header > div:nth-child(4) {
  width: 10%;
}
body #root .table .table_header > div:nth-child(5) {
  width: 10%;
  text-align: center;
}
body #root .table .table_body {
  height: calc(100% - 36px);
  overflow-y: scroll;
}
body #root .table .table_body .table_row {
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-size: 16px;
  height: 52px;
  padding: 0 0 0 16px;
  border-radius: 10px;
}
body #root .table .table_body .table_row:nth-child(odd) {
  background: #f9fafa;
}
body #root .table .table_body .table_row:hover {
  background: var(--accent_alpha_hover);
}
body #root .table .table_body .table_row > div {
  width: 25%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px 0;
}
body #root .table .table_body .table_row > div:nth-child(1) {
  width: 30%;
}
body #root .table .table_body .table_row > div:nth-child(2) {
  width: 20%;
}
body #root .table .table_body .table_row > div:nth-child(3) {
  width: 30%;
  text-align: center;
}
body #root .table .table_body .table_row > div:nth-child(4) {
  width: 10%;
  font-weight: 600;
  position: relative;
}
body #root .table .table_body .table_row > div:nth-child(5) {
  width: 10%;
}
body #root .table .table_body .table_row > div.status {
  color: var(--colorToShow);
  text-transform: uppercase;
}
body #root .table .table_body .table_row > div.address {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
}
body #root .table .table_body .table_row > div.menu_main {
  height: 100%;
  position: relative;
  overflow: unset;
  display: flex;
  justify-content: center;
  align-items: center;
}
body #root .table .table_body .table_row > div.menu_main .menu {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  margin: 0 auto;
  cursor: pointer;
}
body #root .table .table_body .table_row > div.menu_main .menu > .dot {
  --dim: 3px;
  height: var(--dim);
  width: var(--dim);
  background: var(--base);
  border-radius: 50%;
}
body #root .table .table_body .table_row > div.menu_main .menu .menu_box {
  z-index: 1;
  position: absolute;
  top: 100%;
  width: 200px;
  right: 10px;
  border-radius: 0.3rem;
  padding: 4px;
  transform: scale(0);
  transform-origin: top right;
  transition: transform 0.2s linear;
  background: var(--white);
  color: var(--black);
  box-shadow: rgba(5, 26, 46, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.06) 0px 2px 20px;
}
body #root .table .table_body .table_row > div.menu_main .menu .menu_box.active {
  transform: scale(1);
}
body #root .table .table_body .table_row > div.menu_main .menu .menu_box .items {
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 5px;
}
body #root .table .table_body .table_row > div.menu_main .menu .menu_box .items:hover {
  background: var(--grey30);
}
body #root .add_items_modal {
  position: fixed;
  background: var(--modal_bg);
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: top right;
  transform: scale(0);
  opacity: 0;
  transition: transform 0.3s ease-in-out, opacity 0.6s ease-in-out;
  z-index: 100;
  padding: 10px;
}
body #root .add_items_modal[active=true] {
  transform: scale(1);
  opacity: 1;
}
body #root .add_items_modal > .modal {
  width: 500px;
  background: var(--white);
  padding: 30px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
body #root .add_items_modal > .modal .modal_header {
  color: var(--black);
  text-align: center;
}
body #root .add_items_modal > .modal .close {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
body #root .add_items_modal > .modal > div {
  width: 100%;
  font-size: 16px;
}
body #root .add_items_modal > .modal > div:not(:last-child) {
  margin: 0 0 15px 0;
}
body #root .add_items_modal > .modal > div .title {
  margin: 0 0 5px 0;
  font-size: 18px;
}
body #root .add_items_modal > .modal > div textarea {
  font-size: 16px;
  width: 100%;
  resize: none;
  border-radius: 5px;
  outline: 0;
  padding: 10px 15px;
  font-family: "Roboto", sans-serif;
}
body #root .add_items_modal > .modal > div input {
  font-size: 16px;
  width: 100%;
  outline: 0;
  padding: 10px 15px;
  border-radius: 5px;
  font-family: "Roboto", sans-serif;
}
body #root .add_items_modal > .modal > div input[type=time] {
  text-align: center;
}
body #root .add_items_modal > .modal > div .MuiFormControl-root {
  border-radius: 5px;
}
body #root .add_items_modal > .modal > div .MuiFormControl-root svg path {
  fill: var(--grey40);
}
body #root .add_items_modal > .modal .time_group {
  display: flex;
  align-items: center;
  gap: 20px;
}
body #root .add_items_modal > .modal .time_group > .title {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  font-weight: 600;
}
body #root .add_items_modal > .modal .time_group > div:not(.title) {
  width: 50%;
}
body #root .add_items_modal > .modal .btn_group {
  display: flex;
  align-items: center;
  gap: 20px;
}
body #root .add_items_modal > .modal .btn_group > .btn {
  width: 50%;
  margin: 0 auto;
}
body #root .add_plus {
  cursor: pointer;
  background: red;
  border-radius: 10px;
  transition: border-radius 0.3s ease-in-out;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--white);
  font-size: 15px;
  font-weight: 600;
  padding: 6px 16px 6px 10px;
  background: var(--white);
  border-radius: 0.5rem;
  color: var(--base);
}
body #root .edit_items_modal {
  position: fixed;
  background: var(--modal_bg);
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: center;
  transform: scale(0);
  opacity: 0;
  transition: transform 0.3s ease-in-out, opacity 0.6s ease-in-out;
  z-index: 100;
  padding: 10px;
}
body #root .edit_items_modal[active=true] {
  transform: scale(1);
  opacity: 1;
}
body #root .edit_items_modal > .modal {
  width: 500px;
  background: var(--white);
  padding: 30px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
body #root .edit_items_modal > .modal .modal_header {
  color: var(--black);
  text-align: center;
}
body #root .edit_items_modal > .modal .close {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
body #root .edit_items_modal > .modal > div {
  width: 100%;
  font-size: 16px;
}
body #root .edit_items_modal > .modal > div:not(:last-child) {
  margin: 0 0 15px 0;
}
body #root .edit_items_modal > .modal > div .title {
  margin: 0 0 5px 0;
  font-size: 18px;
}
body #root .edit_items_modal > .modal > div textarea {
  font-size: 16px;
  width: 100%;
  resize: none;
  border-radius: 5px;
  outline: 0;
  padding: 10px 15px;
  font-family: "Roboto", sans-serif;
}
body #root .edit_items_modal > .modal > div input {
  font-size: 16px;
  width: 100%;
  outline: 0;
  padding: 10px 15px;
  border-radius: 5px;
  font-family: "Roboto", sans-serif;
}
body #root .edit_items_modal > .modal > div input[type=time] {
  text-align: center;
}
body #root .edit_items_modal > .modal > div .MuiFormControl-root {
  border-radius: 5px;
}
body #root .edit_items_modal > .modal > div .MuiFormControl-root svg path {
  fill: var(--grey40);
}
body #root .edit_items_modal > .modal .time_group {
  display: flex;
  align-items: center;
  gap: 20px;
}
body #root .edit_items_modal > .modal .time_group > .title {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  font-weight: 600;
}
body #root .edit_items_modal > .modal .time_group > div:not(.title) {
  width: 50%;
}
body #root .edit_items_modal > .modal .btn_group {
  display: flex;
  align-items: center;
  gap: 20px;
}
body #root .edit_items_modal > .modal .btn_group > .btn {
  width: 50%;
  margin: 0 auto;
}
body #root .add_plus {
  cursor: pointer;
  background: red;
  border-radius: 10px;
  transition: border-radius 0.3s ease-in-out;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--white);
  font-size: 15px;
  font-weight: 600;
  padding: 6px 16px 6px 10px;
  background: var(--white);
  border-radius: 0.5rem;
  color: var(--base);
}
body #root .view_items_modal {
  position: fixed;
  background: var(--modal_bg);
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: center;
  transform: scale(0);
  opacity: 0;
  transition: transform 0.3s ease-in-out, opacity 0.6s ease-in-out;
  z-index: 100;
}
body #root .view_items_modal[active=true] {
  transform: scale(1);
  opacity: 1;
}
body #root .view_items_modal > div {
  background: var(--white);
  padding: 30px 30px 60px 30px;
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
body #root .view_items_modal > div > div {
  width: 100%;
  font-size: 16px;
}
body #root .view_items_modal > div > div:not(:last-child) {
  margin: 0 0 20px 0;
}
body #root .view_items_modal > div > div.add_items_static > .modal > div {
  width: 100%;
  font-size: 16px;
}
body #root .view_items_modal > div > div.add_items_static > .modal > div:not(:last-child) {
  margin: 0 0 20px 0;
}
body #root .view_items_modal > div > div.add_items_static > .modal > div textarea {
  font-size: 16px;
  border: 1px solid var(--base);
  width: 100%;
  resize: none;
  border-radius: 5px;
  outline: 0;
  padding: 10px 15px;
}
body #root .view_items_modal > div > div.add_items_static > .modal > div input {
  font-size: 16px;
  width: 100%;
  border: 1px solid var(--base);
  outline: 0;
  padding: 10px 15px;
  border-radius: 5px;
}
body #root .view_items_modal > div > div.add_items_static > .modal > div input[type=time] {
  text-align: center;
}
body #root .view_items_modal > div > div.add_items_static > .modal .time_group {
  display: flex;
  align-items: center;
  gap: 20px;
}
body #root .view_items_modal > div > div .txt {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}
body #root .view_items_modal > div > div .txt > .chip {
  background: var(--base);
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  border-radius: 50%;
}
body #root .view_items_modal > div > div .before_container,
body #root .view_items_modal > div > div .after_container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  --gap: 20px;
  gap: var(--gap);
}
body #root .view_items_modal > div > div .before_container .items,
body #root .view_items_modal > div > div .after_container .items {
  width: calc(33.3333333333% - var(--gap) * 2 / 3);
  border-radius: 5px;
  aspect-ratio: 1;
  border: 2px solid var(--base);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
body #root .view_items_modal > div > div .before_container .items img,
body #root .view_items_modal > div > div .before_container .items iframe,
body #root .view_items_modal > div > div .before_container .items video,
body #root .view_items_modal > div > div .after_container .items img,
body #root .view_items_modal > div > div .after_container .items iframe,
body #root .view_items_modal > div > div .after_container .items video {
  max-width: 90%;
  max-height: 90%;
}
body #root .view_items_modal > div > div .before_container .items .delete,
body #root .view_items_modal > div > div .after_container .items .delete {
  position: absolute;
  top: 0;
  right: 0;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--white);
  border-bottom-left-radius: 3px;
  cursor: pointer;
}
body #root .view_items_modal > div > div .before_container .items .delete > div,
body #root .view_items_modal > div > div .after_container .items .delete > div {
  height: 80%;
  width: 80%;
  position: relative;
}
body #root .view_items_modal > div > div .before_container .items .delete > div::after, body #root .view_items_modal > div > div .before_container .items .delete > div::before,
body #root .view_items_modal > div > div .after_container .items .delete > div::after,
body #root .view_items_modal > div > div .after_container .items .delete > div::before {
  content: "";
  position: absolute;
  inset: 0;
  margin: auto;
  height: 100%;
  width: 3px;
  background: var(--black);
}
body #root .view_items_modal > div > div .before_container .items .delete > div::after,
body #root .view_items_modal > div > div .after_container .items .delete > div::after {
  transform: rotate(45deg);
}
body #root .view_items_modal > div > div .before_container .items .delete > div::before,
body #root .view_items_modal > div > div .after_container .items .delete > div::before {
  transform: rotate(-45deg);
}
body #root .view_items_modal > div > div .before_container .add_to_list,
body #root .view_items_modal > div > div .after_container .add_to_list {
  cursor: pointer;
  border-radius: 5px;
  border: 2px solid var(--base);
  width: calc(33.3333333333% - var(--gap) * 2 / 3);
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
body #root .view_items_modal > div > div .before_container .add_to_list img,
body #root .view_items_modal > div > div .after_container .add_to_list img {
  width: 90%;
}
body #root .view_items_modal > div > div .before_container .add_to_list input,
body #root .view_items_modal > div > div .after_container .add_to_list input {
  display: none;
}
body #root .view_items_modal > div .before_after_group {
  display: flex;
  gap: 20px;
}
body #root .view_items_modal > div .before_after_group > div {
  width: 50%;
}
body #root .view_items_modal > div .btn_group {
  display: flex;
  align-items: center;
  gap: 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 10px 30px;
  background: var(--white);
}
body #root .view_items_modal > div .btn_group > .btn {
  width: 50%;
}
body #root .btn {
  cursor: pointer;
  background: var(--base);
  color: var(--white);
  border-radius: 5px;
  padding: 10px 20px;
  text-align: center;
  transition: opacity 0.3s linear;
}
body #root .btn.cancel {
  background: var(--black);
}
body #root .btn[active=false] {
  cursor: no-drop;
  opacity: 0.5;
}
body #root .btn[active=true] {
  opacity: 1;
}
body #root .loader {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--modal_bg);
  z-index: 1000;
  transition: trasnform 0.3s linear;
}
body #root .loader svg {
  height: 200px;
  background: var(--base);
  animation: rotate_bg 3s linear infinite;
}
@keyframes rotate_bg {
  0%, 100% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
}
body #root .loader svg g {
  fill: var(--white);
}
body #root .loader[active=false] {
  transform: scale(0);
}
body #root .loader[active=true] {
  transform: scale(1);
}

.MuiFormControl-root {
  width: 100%;
}
.MuiFormControl-root input {
  height: 30px;
}
.MuiFormControl-root textarea {
  padding: 0 !important;
}